.main {
  display: flex;
  flex-wrap: wrap;
}

.buttonshare {
  width: 50%;
  margin: auto;
}

#logoset:hover,
#nameset:hover,
#companynameset:hover,
#regiaddressset:hover,
#address2set:hover,
#address3set:hover,
#regiaddress2set:hover,
#sloganset:hover,
#contactset:hover,
#designationset:hover,
#empoyeeset:hover,
#addressset:hover,
#emailset:hover,
#hlset:hover,
#userprofileset:hover,
#vlset:hover {
  border: 1px dashed black;
}

.card {
  width: 60%;
  /* margin: 3%; */
  min-width: 650px;
  height: 350px;
  background: #fff;
  position: relative;
  /* padding: 20px; */
  background-image: url("/images/img/templates/img1.jpg");
  background-size: cover;
  overflow: hidden;
  font-family: sans-serif;
  border: 2px solid black;
  box-sizing: "border-box";
  margin-bottom: 0%;
}

.card::before,
.card::after {
  pointer-events: none;
}

.card::before {
  content: "";
  position: absolute;
  border: 4px solid white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.card::after {
  content: "";
  position: absolute;
  border: 3px solid black;
  top: 2px;
  left: 2px;
  width: 99%;
  height: 99%;
  border-radius: 3px;
}

.cardMobile {
  width: 30%;
  margin: 3%;
  min-width: 334px;
  height: 180px;
  background: #fff;
  position: relative;
  background-image: url("/images/img/templates/img4.jpg");
  background-size: cover;
  overflow: hidden;
  font-family: sans-serif;
}

p {
  position: relative;
  /* text-align: center; */
  outline: none;
  font-weight: 100;
  /* margin-top: 20px;
  margin-bottom: 10px; */
  background: none;
  width: 100%;
  border: none;
  font-size: 18px;
  color: black;
  transition: 0.5s;
}

::placeholder {
  text-transform: capitalize;
  color: black;
}

.address {
  margin: 0;
  font-size: 16px;
  width: fit-content;
}

.addressMobile {
  text-align: left;
  font-size: 16px;
  width: fit-content;
}

#mydivaddress {
  /* width: auto; */
  display: flex;
  cursor: move;
  position: absolute;
  max-width: 100%;
  overflow: "hidden";

  /* top: 263px;
  left: 18px; */
}

.email {
  width: fit-content;
  margin: 0;
}

.emailMobile {
  text-align: right;
  width: fit-content;
  font-size: 16px;
}

#mydivemail {
  width: auto;
  display: flex;
  cursor: move;
  position: absolute;

  /* top: 154px;
  left: 20px; */
}

.setting {
  width: 50%;
  padding: 0%;
  margin: 0%;
}

.settingView {
  width: 40%;
  padding: 0%;
  margin: 0%;
  align-content: end;
}

.btnStyle {
  font-size: 16px;
  text-transform: capitalize;
  padding: 10px 20px;
  border-radius: 5px;
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

.btnStyle:focus,
.btnStyle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.backgrounds {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px 0;
}

.backgrounds img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: 0.5s;
  border: 0;
  cursor: pointer;
}

.backgrounds img:hover {
  opacity: 0.5;
}

.backgrounds img.active {
  border: 5px solid #fff;
}
.backgroundsadmin {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px 0;
}
.backgroundsadmin img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: 0.5s;
  border: 0;
  cursor: pointer;
}

.backgroundsadmin img:hover {
  opacity: 0.5;
}

.backgroundsadmin img.active {
  border: 5px solid #4caf50;
}

.name {
  width: fit-content;
  height: auto;
  margin: 0;
  padding: 0;
}

#mydivname {
  position: absolute;
  /* width: auto; */
  height: auto;
  cursor: move;
  max-width: 100%;
  overflow: "hidden";

  /* top: 8px;
  left: 76px; */
}

.companyname {
  width: fit-content;
  height: auto;
  margin: 0;
}

#mydivcompanyname {
  position: absolute;
  width: auto;
  height: auto;
  cursor: move;

  /* top: 132px;
  left: 24px; */
}

.slogan {
  width: fit-content;
  height: auto;
  margin: 0;
}

.sloganMobile {
  font-size: 10px;
  width: fit-content;
  height: auto;
}

#mydivslogan {
  /* width: auto; */
  display: flex;
  cursor: move;
  position: absolute;
  max-width: "100%";
  overflow: hidden;

  /* top: 129px;
  left: 22px; */
}

.contact {
  width: fit-content;
  height: auto;
  margin: 0px;
}

.contactMobile {
  width: fit-content;
  height: auto;
}

#mydivcontact {
  width: auto;
  display: flex;
  cursor: move;
  position: absolute;

  /* top: 203px;
  left: 19px; */
}

.designation {
  width: fit-content;
  height: auto;
  margin: 0;
}

#mydivdesignation {
  /* width: auto; */
  display: flex;
  cursor: move;
  position: absolute;
  max-width: 100%;
  overflow: "hidden";

  /* top: 177px;
  left: 23px; */
}

.empoyee {
  width: fit-content;
  height: auto;
  margin: 0;
}

#mydivempoyeeid {
  width: auto;
  display: flex;
  cursor: move;
  position: absolute;

  /* top: 252px;
  left: 18px; */
}

.hl {
  width: fit-content;
  height: auto;
}

#mydivhl {
  display: flex;
  cursor: move;
  position: absolute;
  border-left: 3px solid green;
  height: 20%;
  /* transform: translate(-50%, -50%);
  top: 50%;
  left: 95%; */

  /* top: 22px;
  left: 195px; */
}

.vl {
  width: fit-content;
  height: auto;
}

#mydivvl {
  display: flex;
  cursor: move;
  position: absolute;
  height: 3px;
  /* border-width: 0; */
  background-color: black;
  width: 90%;
  /* border: 1px solid red;
  margin: 0;
  padding: 0; */
  /* transform: translate(-50%, -50%);
  top: 30%;
  left: 60%; */

  /* top: 257px;
  left: 19px; */
}

.mydivlogo {
  display: flex;
  position: absolute;
  z-index: 9;
  text-align: center;
  width: 20%;
  height: 20%;
  min-height: 10%;
  min-width: 5%;
  max-height: 100%;
  max-width: 100%;

  /* top: 159px;
  left: 302px; */
}

.mydivlogo:hover {
  border: 2px solid black;
}

/*Drgable */

.mydivlogo {
  position: absolute;
  overflow: auto;
  background-image: url("/images/img/logo-static.jpg");
  background-size: cover;
  background-size: 100% 100%;
}

.logo {
  padding: 10px;
  cursor: move;
  background-size: cover;
  background-size: 100% 100%;
}

/*Resizeable*/

.mydivlogo .resizer-right {
  width: 5px;
  height: 100%;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: e-resize;
}

.mydivlogo .resizer-bottom {
  width: 100%;
  height: 5px;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: n-resize;
}

.mydivlogo .resizer-both {
  width: 5px;
  height: 5px;
  background: transparent;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: nw-resize;
}

/*NOSELECT*/

.mydivlogo * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently

    supported by Chrome and Opera */
}

.mydivuserprofile {
  display: flex;
  position: absolute;
  z-index: 9;
  text-align: center;
  min-height: 10%;
  min-width: 5%;
  max-height: 100%;
  max-width: 100%;
  width: 20%;
  height: 20%;
  /* top: 45px;
  left: 26px; */
}

.mydivuserprofile:hover {
  border: 2px solid black;
}

.mydivuserprofile {
  position: absolute;
  overflow: auto;
  background-image: url("/images/img/avatar.png");
  background-size: cover;
  background-size: 100% 100%;
}

.userprofile {
  padding: 10px;
  cursor: move;
  background-size: cover;
  background-size: 100% 100%;
}

.mydivuserprofile .resizer-right {
  width: 5px;
  height: 100%;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: e-resize;
}

.mydivuserprofile .resizer-bottom {
  width: 100%;
  height: 5px;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: n-resize;
}

.mydivuserprofile .resizer-both {
  width: 5px;
  height: 5px;
  background: transparent;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: nw-resize;
}

.mydivuserprofile * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently

    supported by Chrome and Opera */
}

.regiaddress {
  width: fit-content;
  height: auto;
  margin: 0;
}

#mydivregiaddress {
  position: absolute;
  width: auto;
  height: auto;
  cursor: move;
}
.regiaddress2 {
  width: fit-content;
  height: auto;
  margin: 0;
}

#mydivregiaddress2 {
  position: absolute;
  width: auto;
  height: auto;
  cursor: move;
}

.regiaddress3 {
  width: fit-content;
  height: auto;
  margin: 0;
}

#mydivregiaddress3 {
  position: absolute;
  width: auto;
  height: auto;
  cursor: move;
}

.address2 {
  width: fit-content;
  height: auto;
  margin: 0;
  font-size: 16px;
}

#mydivaddress2 {
  position: absolute;
  width: auto;
  height: auto;
  cursor: move;
}
.address3 {
  width: fit-content;
  height: auto;
  margin: 0;
  font-size: 16px;
}

#mydivaddress3 {
  position: absolute;
  width: auto;
  height: auto;
  cursor: move;
}